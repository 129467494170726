





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/deep/.el-input-number.is-without-controls .el-input__inner{
    text-align: left;
}
/deep/.el-input--suffix .el-input__inner{
    padding-right: 15px;
}
.PImgtitle {
    text-align: center;
    max-width: 286px;
    color: #2878ff;
}
.hasRequired /deep/.el-form-item__label:before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
.addDialog /deep/.el-form--label-left .el-form-item__label{
    padding: 10px 0;
    min-height: 40px;
    line-height: 20px;
}
